<template>
    <div class="single-banner-item banner-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-lg-5 col-md-12">
            <div class="main-banner-content">
              <span class="sub-title">DayZ</span>
              <div class="logo">
                <img src="../assets/img/zelda.png" alt="image">
              </div>
              <h6>Neuer Server!</h6>
              <div class="btn-box">
                <a href="#" class="default-btn">Spielen</a>
                <a href="https://discord.zombey.eu/" class="optional-btn" target="_blank">Discord</a>
              </div>
            </div>
          </div>
          <div class="col-lg-7 col-md-12">
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'MainBanner'
  }
  </script>
  
  <style scoped>
  .banner-bg1  {
      min-height: 500px; /* Standardhöhe für größere Bildschirme */
      background-size: cover; /* Stellt sicher, dass der Hintergrund das gesamte Banner bedeckt */
      background-position: center; /* Zentriert den Hintergrund im Banner */
  }
  
  /* Responsive Anpassungen */
  @media (max-width: 992px) { /* Für Tablets */
    .banner-bg1 {
          min-height: 400px; /* Etwas kleinere Höhe für Tablets */
      }
  }
  
  @media (max-width: 768px) {
    .banner-bg1 {
        background-position: 60% center; /* Verschiebt den Fokus des Bildes nach links */
    }
  }
  </style>
  