<template>
    <section class="services-area pt-100 pb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="section-title">
                        <span class="sub-title">Was wir bieten</span>
                        <h2>Entdecke unsere fortschrittlichsten DayZ Mods</h2>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-services-box">
                        <div class="icon">
                            <i class="flaticon-game"></i>
                        </div>
                        <h3>Gemoddete Fahrzeuge</h3>
                        <p>Erkunde Chernarus wie nie zuvor mit unseren einzigartigen, vollständig modifizierten Fahrzeugen. Ob Geländewagen oder Militärfahrzeuge, wir bieten eine beeindruckende Auswahl, die jede Reise spannend macht.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-services-box">
                        <div class="icon">
                            <i class="flaticon-card-game"></i>
                        </div>
                        <h3>PVP & PVE Zonen</h3>
                        <p>Tauche ein in intensiven PVP- und PVE-Content. Unsere klar definierten Zonen bieten sowohl erbarmungslose Spielerduelle als auch spannende PVE-Herausforderungen gegen Umwelt und KI-gesteuerte Feinde.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-services-box">
                        <div class="icon">
                            <i class="flaticon-slot-machine"></i>
                        </div>
                        <h3>Trader & Basebuilding</h3>
                        <p>Baue dein Überlebensimperium mit fortschrittlichem Basebuilding und einem umfassenden Händlersystem auf. Sichere Ressourcen und tausche clever, um dein Überleben zu sichern.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-services-box">
                        <div class="icon">
                            <i class="flaticon-3d"></i>
                        </div>
                        <h3>Balanced PVP System</h3>
                        <p>Unser fein abgestimmtes PVP-System sorgt für faire und spannende Kämpfe. Egal, ob Anfänger oder Veteran, unser Balancing bietet jedem Spieler eine faire Chance.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-services-box">
                        <div class="icon">
                            <i class="flaticon-website"></i>
                        </div>
                        <h3>Gruppen System</h3>
                        <p>Verbessere deine Überlebenschancen durch unser dynamisches Gruppensystem. Gruppenmitglieder werden markiert, Wegpunkte können gemeinsam eingesehen werden.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-services-box">
                        <div class="icon">
                            <i class="flaticon-game-controller"></i>
                        </div>
                        <h3>Viele weitere Dinge</h3>
                        <p>Entdecke zahlreiche zusätzliche Features und tägliche Events, die Deine DayZ-Erfahrung bereichern. Jeder Tag bringt neue Überraschungen und Herausforderungen!</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
  name: 'ModsComponent'
}
</script>
