<template>
    <div class="sidebarModal modal right fade" id="sidebarModal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <button type="button" class="close" data-bs-dismiss="modal"><i class='bx bx-x'></i></button>
          <div class="modal-body">
            <div class="logo">
              <a href="/" class="d-inline-block"><img src="../assets/img/logo.png" alt="image"></a>
            </div>
            <div class="instagram-list">
              <div class="row">
                <!-- Repeated elements go here -->
              </div>
            </div>
            <div class="sidebar-contact-info">
              <h2>
                <a href="tel:+8802419268615">+880 241 926 8615</a>
                <span>OR</span>
                <a href="mailto:hello@zelda.com">hello@zelda.com</a>
              </h2>
            </div>
            <ul class="social-list">
              <li><span>Follow Us On:</span></li>
              <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
              <!-- Other icons -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'SidebarComponent'
  }
  </script>
  
  <style scoped>
  /* Styles specific to SidebarModal */
  </style>
  