<template>
    <section class="blog-area pb-100 top-team-slides">
      <div class="container">
        <div class="section-title">
          <span class="sub-title">Infos</span>
          <h2>Unser DayZ-Server</h2>
        </div>
      </div>
  
      <div class="carousel-container">
        <Flicking :options="{ circular: true, defaultIndex:1, panelsPerView: -1 }" :plugins="plugins">
          <template v-slot:default>
            <div v-for="info in infos" :key="info.id" class="slide-item">
              <div class="post-image">
                <div @click="openModal(info.logo)" class="post-image">
                  <img :src="require(`@/assets/img/${info.logo}`)" alt="image" class="img-fluid">
                </div>
              </div>
              <div class="post-content">
                <span class="sub-title">{{ info.subTitle }}</span>
                <h3><a href="">{{ info.text }}</a></h3>
              </div>
            </div>
          </template>
          <template #viewport>
            <span slot="viewport" class="flicking-arrow-prev"></span>
            <span slot="viewport" class="flicking-arrow-next"></span>
            <div slot="viewport" class="flicking-pagination"></div>
          </template>
        </Flicking>
      </div>
    </section>
    <div v-if="showModal" class="modal-overlay" @click="closeModal">
    <div class="modal-content" @click.stop>
      <span class="close-button" @click="closeModal">&times;</span>
      <img :src="require(`@/assets/img/${currentImage}`)" alt="Zoomed image" class="zoomed-img">
    </div>
  </div>
  </template>
  
  <script>
  import Flicking from "@egjs/vue3-flicking";
  import { Arrow, Pagination } from "@egjs/flicking-plugins";
  import "@egjs/vue3-flicking/dist/flicking.css";
  import "@egjs/flicking-plugins/dist/arrow.css";
  
  export default {
    components: {
      Flicking
    },
    data() {
      return {
        infos: [
          { id: 0, name: "img1", logo: "advanced_groups.jpg", subTitle: "Feature", text: "Gruppen System mit interaktive Map" },
          { id: 1, name: "img2", logo: "banking_system.jpg", subTitle: "Feature", text: "Erweitertes Banking mit Robfunktion" },
          { id: 2, name: "img3", logo: "spawn_system.jpg", subTitle: "Feature", text: "Spawnauswahl mit Sleepingbags" },
          { id: 3, name: "img4", logo: "virtuell_garage.jpg", subTitle: "Feature", text: "Virtuelle Garage" },
          { id: 4, name: "img5", logo: "earplugs.jpg", subTitle: "Feature", text: "Ohrenstöpfsel" },
          { id: 5, name: "img6", logo: "keycard_system.jpg", subTitle: "Feature", text: "Keycard System für Hightier Loot" },
          { id: 6, name: "img7", logo: "KOTH_System.jpg", subTitle: "Feature", text: "King of the Hill" },
        ],
        plugins: [new Arrow(), new Pagination({ type: 'bullet' })],
        showModal: false,
        currentImage: ''
      };
    },
    mounted() {
      // Listen for keydown events on the whole document
      document.addEventListener('keydown', this.handleKeydown);
    },
    
    beforeUnmount() {
      // Clean up the listener when the component is destroyed
      document.removeEventListener('keydown', this.handleKeydown);
    },

    methods: {
      openModal(image) {
        this.currentImage = image;
        this.showModal = true;
      },
      closeModal() {
        this.showModal = false;
      },
      handleKeydown(event) {
        // Check if the 'Esc' key was pressed
        if (event.key === "Escape") {
          this.closeModal();
        }
      }
    }
  }
  </script>  
  
  <style scoped>
  .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.modal-content {
  position: relative;
  padding: 20px;
  max-width: 80%;
  max-height: 80%;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 30px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.zoomed-img {
  width: 100%;
  height: auto;
  max-height: 100%;
}

  .blog-area {
    padding-top: 100px; /* Increases the top padding in the blog area */
  }
  
  .top-team-slides {
    max-width: 90%;
    margin: auto;
    position: relative; /* Ensures that absolute positioning of children (arrows) is relative to this container */
  }
  
  .carousel-container {
    overflow: hidden;
    width: 100%;
    padding: 20px 0; /* Adds padding above and below */
  }
  
  .slide-item {
    flex: 0 0 calc(100% / 3 - 20px); /* Divides the container into 3 equal parts, accounting for spacing */
    margin: 0 10px; /* Adds horizontal spacing between slides */
    text-align: center;
    overflow: hidden; /* Prevents content from overflowing */
  }
  
  .post-image img {
  width: 100%;  /* Setzt die Breite auf 100% des Elternelements */
  height: auto; /* Setzt die Höhe automatisch basierend auf dem Seitenverhältnis des Bildes */
  max-height: 300px; /* Setzt eine maximale Höhe */
  object-fit: cover; /* Stellt sicher, dass das Bild das Container-Element voll ausfüllt, ohne dass das Seitenverhältnis verzerrt wird */
}
  
  .flicking-arrow-prev,
  .flicking-arrow-next {
    z-index: 1000;
    position: absolute; /* Absolute positioning within the carousel container */
    top: 50%; /* Centers the arrows vertically */
    transform: translateY(-50%);  /* Adjusts positioning to be centered on the edge */
    width: 30px; /* Increases width for visibility */
    height: 30px; /* Increases height for visibility */
    border-top: 4px solid #df2215; /* Thicker top border */
    border-right: 4px solid #df2215; /* Thicker right border */
    background: transparent; /* Ensures no background color */
    cursor: pointer; /* Changes cursor to a pointer to indicate clickable area */
  }
  
  .flicking-arrow-prev {
    left: 20px; /* Adjusts left position for the previous button */
    transform: rotate(-135deg); /* Rotates the arrow to point left */
  }
  
  .flicking-arrow-next {
    right: 20px; /* Adjusts right position for the next button */
    transform: rotate(45deg); /* Rotates the arrow to point right */
  }
  
  .flicking-arrow-prev:hover,
  .flicking-arrow-next:hover {
    background-color: transparent; /* Keeps the background transparent */
    border-top-color: #ff6347; /* Changes the top border color to a lighter red */
    border-right-color: #ff6347; /* Changes the right border color to match */
  }

  @media (max-width: 768px) {
  .flicking-arrow-prev,
  .flicking-arrow-next {
    width: 20px; /* Smaller arrows */
    height: 20px;
  }

  .flicking-arrow-prev {
    left: 10px;
  }

  .flicking-arrow-next {
    right: 10px;
  }
}

@media (max-width: 480px) {
  .flicking-arrow-prev,
  .flicking-arrow-next {
    width: 15px; /* Even smaller for very small devices */
    height: 15px;
  }

  .flicking-arrow-prev {
    left: 5px;
  }

  .flicking-arrow-next {
    right: 5px;
  }
}
  </style>
  