<template>
  <div>
    <app-navbar />
    <router-view />
    <sidebar-component />
    <search-component />
    <app-footer />
  </div>
</template>


<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import AppNavbar from './components/layouts/AppNavbar.vue'
import AppFooter from './components/layouts/AppFooter.vue'
import SidebarComponent from './components/SidebarComponent.vue'
import SearchComponent from './components/SearchComponent.vue'
import MainBannerComponent from './components/MainBannerComponent.vue'
import ServerinfoComponent from './components/ServerinfoComponent.vue'
import MatchesComponent from './components/MatchesComponent.vue'
import ModsComponent from './components/ModsComponent.vue'
import PartnersComponent from './components/PartnersComponent.vue'

const route = useRoute();
const isHomePage = computed(() => route.path === '/');
</script>

<style>
</style>
