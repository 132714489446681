/**
 * Cursor JS
 */
import jQuery from 'jquery';

jQuery(document).ready(function() {
    const r = document.querySelector(".zelda-cursor");
    const t = document.querySelector(".zelda-cursor2");

    if (jQuery(".zelda-cursor")[0]) {
        let cursorPosition = {x: 0, y: 0, largeX: 0, largeY: 0, targetX: 0, targetY: 0};

        document.addEventListener("mousemove", event => {
            cursorPosition.targetX = event.pageX;
            cursorPosition.targetY = event.pageY;
        });

        const updateCursorPosition = () => {
            cursorPosition.x += 0.2 * (cursorPosition.targetX - cursorPosition.x);
            cursorPosition.y += 0.2 * (cursorPosition.targetY - cursorPosition.y);
            cursorPosition.largeX += 0.12 * (cursorPosition.targetX - cursorPosition.largeX);
            cursorPosition.largeY += 0.12 * (cursorPosition.targetY - cursorPosition.largeY);

            r.style.transform = `translate(${cursorPosition.x - 5}px, ${cursorPosition.y - 5}px)`;
            t.style.transform = `translate(${cursorPosition.largeX - 25}px, ${cursorPosition.largeY - 25}px)`;

            requestAnimationFrame(updateCursorPosition);
        };

        updateCursorPosition();
    }
});
