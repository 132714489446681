<template>
    <!-- Start Navbar Area -->
    <div class="navbar-area navbar-style-two">
        <div class="zelda-responsive-nav">
            <div class="container">
                <div class="zelda-responsive-menu">
                    <div class="logo">
                        <a href="/">
                            <img src="../../assets/img/logo.png" alt="logo">
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="zelda-nav">
            <div class="container-fluid">
                <nav class="navbar navbar-expand-md navbar-light">
                    <a class="navbar-brand" href="/">
                        <img src="../../assets/img/logo.png" alt="logo">
                    </a>

                    <div class="collapse navbar-collapse mean-menu">
                        <ul class="navbar-nav">
                            <li class="nav-item"><a href="/" class="nav-link active">Home <i class="flaticon-down"></i></a></li>

<!--                             <li class="nav-item"><a href="#" class="nav-link">Pages <i class="flaticon-down-arrow"></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a href="howto.html" class="nav-link">How to play</a></li>

                                    <li class="nav-item"><router-link to="/team" class="nav-link">Our Team</router-link></li>

                                    <li class="nav-item"><router-link to="/rules" class="nav-link">Rules</router-link></li>

                                    <li class="nav-item"><router-link to="/faq" class="nav-link">FAQ</router-link></li>
                                </ul>
                            </li> -->

                            <!-- <li class="nav-item"><a href="#" class="nav-link">Shop <i class="flaticon-down-arrow"></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a href="products-list-1.html" class="nav-link">Products List 01</a></li>

                                    <li class="nav-item"><a href="products-list-2.html" class="nav-link">Products List 02</a></li>

                                    <li class="nav-item"><a href="cart.html" class="nav-link">Cart</a></li>

                                    <li class="nav-item"><a href="checkout.html" class="nav-link">Checkout</a></li>

                                    <li class="nav-item"><a href="single-products.html" class="nav-link">Products Details</a></li>

                                    <li class="nav-item"><a href="my-account.html" class="nav-link">My Account</a></li>
                                </ul>
                            </li>

                            <li class="nav-item"><a href="#" class="nav-link">Blog <i class="flaticon-down-arrow"></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a href="blog-1.html" class="nav-link">Grid (2 in Row)</a></li>

                                    <li class="nav-item"><a href="blog-2.html" class="nav-link">Grid (3 in Row)</a></li>

                                    <li class="nav-item"><a href="blog-3.html" class="nav-link">Masonry (3 in Row)</a></li>

                                    <li class="nav-item"><a href="blog-4.html" class="nav-link">Right Sidebar</a></li>

                                    <li class="nav-item"><a href="blog-5.html" class="nav-link">Left Sidebar</a></li>

                                    <li class="nav-item"><a href="#" class="nav-link">Single Post <i class='bx bx-plus'></i></a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item"><a href="single-blog-1.html" class="nav-link">Default</a></li>
                                            
                                            <li class="nav-item"><a href="single-blog-2.html" class="nav-link">With Video</a></li>
                                            
                                            <li class="nav-item"><a href="single-blog-3.html" class="nav-link">With Image Slider</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li> -->

                            <li class="nav-item"><router-link to="/zombie" class="nav-link">Zombie</router-link></li>
                            <!-- <li class="nav-item"><router-link to="/contact" class="nav-link">Contact</router-link></li> -->
                        </ul>

                        <!-- <div class="others-option d-flex align-items-center">
                            <div class="option-item">
                                <div class="cart-btn">
                                    <a href="cart.html">
                                        <i class="flaticon-null-5"></i>
                                        <span>1</span>
                                    </a>
                                </div>
                            </div>

                            <div class="option-item">
                                <div class="search-box">
                                    <i class="flaticon-search-1"></i>
                                </div>
                            </div>

                            <div class="option-item">
                                <div class="side-menu-btn">
                                    <i class="flaticon-null-2" data-bs-toggle="modal" data-bs-target="#sidebarModal"></i>
                                </div>
                            </div>
                        </div> -->
                        
                        <div class="dark-version-btn">
                            <label id="switch" class="switch">
                                <input type="checkbox" @change="toggleTheme" :checked="isLightTheme" id="slider">
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div>
                </nav>
            </div>
        </div>

        <div class="others-option-for-responsive">
            <div class="container">
                <div class="dot-menu" @click="toggleMenu">
                    <div class="inner">
                        <div class="circle circle-one"></div>
                        <div class="circle circle-two"></div>
                        <div class="circle circle-three"></div>
                    </div>
                </div>
                
                <div :class="{'container': true, 'active': isActive}">
                    <div class="option-inner">
                        <div class="others-option">
                            <div class="option-item">
                                <div class="cart-btn">
                                    <a href="#">
                                        <i class="flaticon-null-5"></i>
                                        <span>1</span>
                                    </a>
                                </div>
                            </div>

                            <div class="option-item">
                                <div class="search-box">
                                    <i class="flaticon-search-1"></i>
                                </div>
                            </div>

                            <div class="option-item">
                                <div class="side-menu-btn">
                                    <i class="flaticon-null-2" data-bs-toggle="modal" data-bs-target="#sidebarModal"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Navbar Area -->
</template>

<script>
export default {
    name: 'AppNavbar',
    data() {
        return {
            isActive: false, // Steuert die Sichtbarkeit des Menüs
        };
    },
    computed: {
        isLightTheme() {
            return localStorage.getItem('zelda_theme') === 'theme-light';
        }
    },
    methods: {
        setTheme(themeName) {
            localStorage.setItem('zelda_theme', themeName);
            document.documentElement.className = themeName;
        },
        toggleTheme() {
            if (localStorage.getItem('zelda_theme') === 'theme-light') {
                this.setTheme('theme-dark');
            } else {
                this.setTheme('theme-light');
            }
        },
        toggleMenu() {
            this.isActive = !this.isActive; // Wechselt den Zustand der aktiven Klasse
        },
        initializeMeanMenu() {
            jQuery('.mean-menu').meanmenu({
                meanScreenWidth: "1199", // Setzt den Breakpoint für die mobile Ansicht
            });
        },
    },
    mounted() {
        this.initializeMeanMenu();
        if (!localStorage.getItem('zelda_theme')) {
            this.setTheme('theme-light');
        } else {
            this.setTheme(localStorage.getItem('zelda_theme'));
        }
    }
}
</script>

<style scoped>
.container.active {
    display: block; /* Beispiel: Einstellung, um den Container sichtbar zu machen */
    /* Fügen Sie weitere Stile hinzu, die notwendig sind, um die Sichtbarkeit zu ändern */
}
</style>