<template>
    <div class="partner-area bg-1b060a pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <span class="sub-title">Mod Partner</span>
            </div>
            
            <Vue3Marquee>
                <template v-for="partner in partners" :key="partner.id">
                    <div class="single-partner-item">
                        {{partner.name}}
                    </div>
                </template>
            </Vue3Marquee>
        </div>
    </div>
</template>


<script setup>
import { ref } from 'vue';
import { Vue3Marquee } from 'vue3-marquee';

const partners = ref([
  { id: 1, name: "LBMASTER" },
  { id: 2, name: "SIBNIC" },
  { id: 3, name: "DIMITRI" },
  { id: 4, name: "Gurdian" },
  { id: 5, name: "Roosgun" },
]);
</script>

<style scoped>
.partner-area {
  background-color: #1b060a; /* Sets a dark background for the partner area */
  padding-top: 100px; /* Top padding */
  padding-bottom: 70px; /* Bottom padding */
}

.section-title .sub-title {
  font-size: 48px; /* Larger font size for subtitles */
  color: #ffffff; /* White color for better visibility on dark background */
  text-align: center; /* Centers the subtitle text */
  margin-bottom: 20px; /* Space below the subtitle */
}

.single-partner-item {
  font-size: 48px; /* Starting font size for partner names */
  color: #ffffff; /* White color for text */
  text-align: center; /* Ensures text is centered */
  padding: 10px 20px; /* Padding around the text for spacing */
  margin: 0 auto; /* Centering in marquee */
  white-space: nowrap; /* Keeps the text on one line */
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .single-partner-item {
    font-size: 18px; /* Slightly smaller font size on smaller devices */
  }
}

@media (max-width: 480px) {
  .single-partner-item {
    font-size: 16px; /* Even smaller font size for very small devices */
  }
}
</style>
