<template>
    <!-- Start Page Title Area -->
    <section class="page-title-area page-title-bg1">
        <div class="container">
            <div class="page-title-content">
                <h1 title="Team">Team</h1>
            </div>
        </div>
    </section>
    <!-- End Page Title Area -->

    <!-- Start Team Area -->
    <section class="team-area ptb-100">
      <div class="container">
        <div class="row">
          <div v-for="member in teamMembers" :key="member.id" class="col-lg-3 col-md-4 col-sm-6">
            <div class="single-team-member">
              <img :src="member.image" :alt="`Image of ${member.name}`">
  
              <div class="content">
                <h3>{{ member.name }}</h3>
                <span>{{ member.handle }}</span>
              </div>
  
              <a :href="member.profileUrl" class="link-btn"></a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Team Area -->
  </template>
  
  <script>
  export default {
    name: 'TeamComponent',
    data() {
      return {
        teamMembers: [
          { id: 1, name: 'Sarah Taylor', handle: 'Sarah3s', image: '../assets/img/team-member1.jpg', profileUrl: 'single-team.html' },
          { id: 2, name: 'Merv Adrian', handle: 'Zelda3s', image: '../assets/img/team-member2.jpg', profileUrl: 'single-team.html' },
          { id: 3, name: 'Carla Gentry', handle: '3s.King', image: '../assets/img/team-member3.jpg', profileUrl: 'single-team.html' },
          { id: 4, name: 'Liam Oliver', handle: 'Junior.Zelda', image: '../assets/img/team-member4.jpg', profileUrl: 'single-team.html' },
          { id: 5, name: 'Emma Olivia', handle: 'King.Zelda', image: '../assets/img/team-member5.jpg', profileUrl: 'single-team.html' },
          { id: 6, name: 'Elijah William', handle: 'Zelda', image: '../assets/img/team-member6.jpg', profileUrl: 'single-team.html' },
          { id: 7, name: 'Kylo Jennar', handle: 'Zelda.Kylo', image: '../assets/img/team-member8.jpg', profileUrl: 'single-team.html' },
          { id: 8, name: 'Lucas Luiz', handle: 'luiz.zelda', image: '../assets/img/team-member7.jpg', profileUrl: 'single-team.html' }
        ]
      };
    }
  }
  </script>
  
  <style scoped>
  /* Add any styles specific to this component here */
  </style>
  