<template>
    <div class="search-overlay">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="search-overlay-layer"></div>
          <div class="search-overlay-layer"></div>
          <div class="search-overlay-layer"></div>
          <div class="search-overlay-close">
            <span class="search-overlay-close-line"></span>
            <span class="search-overlay-close-line"></span>
          </div>
          <div class="search-overlay-form">
            <form>
              <input type="text" class="input-search" placeholder="Search here...">
              <button type="submit"><i class="flaticon-search-1"></i></button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'SearchOverlay'
  }
  </script>
  
  <style scoped>
  /* Styles specific to SearchOverlay */
  </style>
  