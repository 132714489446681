// main.js
import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'

// Importiere deine Komponenten
import Pages from './components/Pages.vue';
import MainBannerComponent from './components/MainBannerComponent.vue'
import FaqComponent from './components/FaqComponent.vue'
import ContactComponent from './components/ContactComponent.vue'
import TeamComponent from './components/TeamComponent.vue'
import RulesComponent from './components/RulesComponent.vue'
import ZombieComponent from './components/ZombieComponent.vue'

import '@/assets/js/parallax.min.js';
import '@/assets/js/meanmenu.min.js';
import '@/assets/js/fancybox.min.js';
import '@/assets/js/cursor.min.js';
import '@/assets/js/owl.carousel.min.js';
import '@/assets/js/main.js';

import './assets/css/bootstrap.min.css';
import './assets/css/animate.min.css';
import './assets/css/boxicons.min.css';
import './assets/css/meanmenu.min.css';
import './assets/css/flaticon.css';
import './assets/css/fancybox.min.css';
import './assets/css/owl.carousel.min.css';
import './assets/css/owl.theme.default.min.css';
import './assets/css/magnific-popup.min.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import './assets/css/dark-style.css';

// Definiere deine Routen
const routes = [
  { path: '/', component: Pages },
  { path: '/index-2.html', redirect: '/' },
  { path: '/faq', component: FaqComponent },
  { path: '/contact', component: ContactComponent},
  { path: '/team', component: TeamComponent},
  { path: '/rules', component: RulesComponent},
  { path: '/zombie', component: ZombieComponent}
]

// Erstelle den Router
const router = createRouter({
  history: createWebHistory(),
  routes,
})

// Erstelle die App und füge den Router hinzu
createApp(App).use(router).mount('#app')
