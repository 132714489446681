<template>
<div>
<div class="footer-area-bg-image">

<!-- Start Subscribe Area -->
<section class="subscribe-area">
    <div class="container">
        <div class="subscribe-inner ptb-100 jarallax">
            <span class="sub-title">Community</span>
            <h2>Trete unserer Community bei!</h2>
            <a href="https://discord.zombey.eu/" class="default-btn" target="_blank">Discord beitreten</a>
        </div>
    </div>
</section>
<!-- End Subscribe Area -->

<!-- Start Social Area -->
<section class="social-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Social Media</span>
            <h2>Bleibe aktuell</h2>
        </div>

        <div class="row">
            <div class="col-lg-2 col-sm-4 col-md-4 col-6">
                <div class="single-social-box">
                    <div class="content">
                        <i class='bx bxl-twitch'></i>
                        Twitch
                    </div>
                    <div class="shape">
                        <img src="../../assets/img/social-shape1.png" alt="image">
                        <img src="../../assets/img/social-shape2.png" alt="image">
                    </div>
                    <a href="#" target="_blank" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4 col-md-4 col-6">
                <div class="single-social-box">
                    <div class="content">
                        <i class='bx bxl-facebook'></i>
                        Facebook
                    </div>
                    <div class="shape">
                        <img src="../../assets/img/social-shape1.png" alt="image">
                        <img src="../../assets/img/social-shape3.png" alt="image">
                    </div>
                    <a href="#" target="_blank" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4 col-md-4 col-6">
                <div class="single-social-box">
                    <div class="content">
                        <i class='bx bxl-twitter'></i>
                        Twitter
                    </div>
                    <div class="shape">
                        <img src="../../assets/img/social-shape1.png" alt="image">
                        <img src="../../assets/img/social-shape4.png" alt="image">
                    </div>
                    <a href="#" target="_blank" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4 col-md-4 col-6">
                <div class="single-social-box">
                    <div class="content">
                        <i class='bx bxl-youtube'></i>
                        YouTube
                    </div>
                    <div class="shape">
                        <img src="../../assets/img/social-shape1.png" alt="image">
                        <img src="../../assets/img/social-shape5.png" alt="image">
                    </div>
                    <a href="#" target="_blank" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4 col-md-4 col-6">
                <div class="single-social-box">
                    <div class="content">
                        <i class='bx bxl-instagram'></i>
                        Instagram
                    </div>
                    <div class="shape">
                        <img src="../../assets/img/social-shape1.png" alt="image">
                        <img src="../../assets/img/social-shape6.png" alt="image">
                    </div>
                    <a href="#" target="_blank" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4 col-md-4 col-6">
                <div class="single-social-box">
                    <div class="content">
                        <i class='bx bxl-vimeo'></i>
                        Vimeo
                    </div>
                    <div class="shape">
                        <img src="../../assets/img/social-shape1.png" alt="image">
                        <img src="../../assets/img/social-shape7.png" alt="image">
                    </div>
                    <a href="#" target="_blank" class="link-btn"></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Social Area -->

<!-- Start Footer Area -->
<footer class="footer-area">
    <div class="container">
        <div class="footer-content">
            <div class="logo">
                <a href="/" class="d-inline-block"><img src="../../assets/img/logo.png" alt="image"></a>
                <ul class="footer-menu">
                    <li class="nav-item"><a class="nav-link" href="#">Legal</a></li>
                    <li class="nav-item"><a class="nav-link" href="#">Terms of Use</a></li>
                    <li class="nav-item"><a class="nav-link" href="#">Privacy policy</a></li>
                    <li class="nav-item"><a class="nav-link" href="#">Cockie setting</a></li>
                    <li class="nav-item"><a class="nav-link" href="#">Support center</a></li>
                </ul>
            </div>
        </div>
    </div>
</footer>

<div class="copyright-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 col-sm-5">
                <p>© 2024 by Zombey</p>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-7">
                <div class="lang-switcher">
                    <span>Select your language</span>
                    <select>
                        <option>English</option>
                        <option>Spanish</option>
                        <option>French</option>
                        <option>Portuguese</option>
                      </select>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Footer Area -->

</div>

<div class="go-top"><i class='bx bx-up-arrow-alt'></i></div>

<div class="zelda-cursor"></div>
<div class="zelda-cursor2"></div>
</div>
</template>

<script>
    export default {
        name: 'AppFooter'
    }
</script>