<template>
    <div>
      <main-banner-component />
      <serverinfo-component />
      <matches-component />
      <mods-component />
      <partners-component />
    </div>
  </template>
  
  <script>
  // Import the components if they are not globally registered
  import MainBannerComponent from './MainBannerComponent.vue';
  import ServerinfoComponent from './ServerinfoComponent.vue';
  import MatchesComponent from './MatchesComponent.vue';
  import ModsComponent from './ModsComponent.vue';
  import PartnersComponent from './PartnersComponent.vue';
  
  export default {
    components: {
      MainBannerComponent,
      ServerinfoComponent,
      MatchesComponent,
      ModsComponent,
      PartnersComponent
    }
  }
  </script>
  