<template>
    <!-- Start Page Title Area -->
    <section class="page-title-area page-title-bg1">
        <div class="container">
            <div class="page-title-content">
                <h1 title="Rules">Rules</h1>
            </div>
        </div>
    </section>
    <!-- End Page Title Area -->

    <!-- Start Rules Area -->
    <section class="rules-area ptb-100">
        <div class="container">
            <div class="rules-content">
                <ul class="rules-list">
                    <li class="rules-item">
                        <h2><i class="bx bx-shield"></i> Destructive Behaviour</h2>
                        <p>While competitive conduct is expected, respect for all players is mandatory. Immature behavior, harassment, and insults will not be tolerated.</p>
                    </li>
                    <li class="rules-item">
                        <h2><i class="bx bx-bug"></i> Glitching/Game Weakness Abuse/Cheating</h2>
                        <p>Exploiting game bugs, using cheats, or any form of duping will lead to a permanent ban. This includes hiding in glitched locations or manipulating game mechanics unfairly.</p>
                    </li>
                    <li class="rules-item">
                        <h2><i class="bx bx-ghost"></i> Ghosting</h2>
                        <p>Using multiple accounts in a single game session to gain an unfair advantage is prohibited and will result in a ban.</p>
                    </li>
                    <li class="rules-item">
                        <h2><i class="bx bx-log-out"></i> Combat Logging</h2>
                        <p>Logging out during combat to save oneself from a loss is strictly forbidden and against the spirit of fair play.</p>
                    </li>
                    <li class="rules-item">
                        <h2><i class="bx bx-home"></i> Base Building</h2>
                        <p>Bases must have accessible gates or locks to maintain gameplay balance and fairness.</p>
                    </li>
                    <li class="rules-item">
                        <h2><i class="bx bx-video"></i> Stream Sniping</h2>
                        <p>Watching live streams for gaining in-game advantages over streamers is not allowed and undermines community integrity.</p>
                    </li>
                    <li class="rules-item">
                        <h2><i class="bx bx-lock"></i> Base Raiding</h2>
                        <p>Interfering with other players' bases unfairly, such as locking their gates externally, is not permitted.</p>
                    </li>
                    <li class="rules-item">
                        <h2><i class="bx bx-recycle"></i> Loot Cycling</h2>
                        <p>Manipulating loot spawn mechanisms by artificially cycling items is prohibited.</p>
                    </li>
                </ul>
            </div>
        </div>
    </section>
    <!-- End Rules Area -->
</template>

<style scoped>
.rules-list {
    list-style: none;
    padding: 0;
    margin: 20px 0;
}
.rules-item {
    border-bottom: 1px solid #ccc;
    padding: 20px;
    transition: background-color 0.3s;
}
.rules-item:last-child {
    border-bottom: none;
}
.rules-item:hover {
    background-color: #f8f8f8;
}
.rules-item h2 {
    font-size: 1.5rem;
    color: #333;
    display: flex;
    align-items: center;
}
.rules-item h2 i {
    font-size: 1.5rem;
    margin-right: 10px;
    color: var(--primary-color); /* Use your theme's primary color here */
}
.rules-item p {
    font-size: 1rem;
    color: #666;
    margin-top: 5px;
}
</style>
