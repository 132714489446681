<template>
    <!-- Start Page Title Area -->
    <section class="page-title-area page-title-bg1">
        <div class="container">
            <div class="page-title-content">
                <h1 title="Faqs">Zombie</h1>
            </div>
        </div>
    </section>
    <!-- End Page Title Area -->

    <div ref="canvasContainer" style="width: 100%; height: 500px;"></div>
</template>

<script>
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

export default {
  mounted() {
    this.$nextTick(() => {
      this.initThreeJS();
    });
  },
  methods: {
    initThreeJS() {
      if (!this.$refs.canvasContainer) {
        console.error("Canvas container is not defined.");
        return;
      }

      const width = this.$refs.canvasContainer.clientWidth;
      const height = this.$refs.canvasContainer.clientHeight;

      const renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(width, height);
      renderer.setPixelRatio(window.devicePixelRatio); // Verbessert die Bildqualität auf hochauflösenden Displays
      this.$refs.canvasContainer.appendChild(renderer.domElement);

      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
      camera.position.z = 2; // Näheres Heranzoomen

      const controls = new OrbitControls(camera, renderer.domElement);
      controls.update();

      const ambientLight = new THREE.AmbientLight(0x404040);
      scene.add(ambientLight);
      const hemisphereLight = new THREE.HemisphereLight(0xffffbb, 0x080820, 1);
      scene.add(hemisphereLight);

      const loader = new GLTFLoader();
      loader.load(
        '/assets/models/zombey/source/Zombie.glb',
        (gltf) => {
          scene.add(gltf.scene);
          animate();
        },
        (xhr) => {
          console.log(`Model is ${Math.round((xhr.loaded / xhr.total) * 100)}% loaded`);
        },
        (error) => {
          console.error('An error happened during loading the model:', error);
        }
      );

      const animate = () => {
        requestAnimationFrame(animate);
        controls.update();
        renderer.render(scene, camera);
      };
    }
  }
};
</script>
